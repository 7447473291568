import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Contact } from 'src/app/objects/contact';
import { RecaptchaResponse } from '../objects/recaptcha-response';
import { ContactWholesale } from '../objects/contact-wholesale';

declare let grecaptcha: any;

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  verifyUrl: string = 'https://recapture-organics-fn.azurewebsites.net/api/VerifyRecaptcha?code=aL6vq6RxfXeOsnZE5qdzAt13L3rB9sumZ0caC3Z5oW1/xsI8a1qgIQ==';
  contactUrl: string = 'https://recapture-organics-fn.azurewebsites.net/api/SendEmail?code=Fz82zR2MdfZvZlmtsh7hYVrtT7tdJ5sO61BqcGawW4YWj9Rw5Y3z/A==';
  contactWholesaleUrl: string = 'https://recapture-organics-fn.azurewebsites.net/api/SendWholesaleEmail?code=ZzCXxn6LMv5drL0JSeLyEbVH779dTFjs6WerLT1xksZtEUQ8JSdnAg==';
  contact: Contact;
  contactWholesale: ContactWholesale;
  success: boolean = false;
  subSuccess: Subject<boolean> = new Subject<boolean>();
  obsSuccess: Observable<boolean> = this.subSuccess.asObservable();

  constructor(
    private httpClient:HttpClient
  ) { }

  sendEmail (contact: Contact) {
    this.contact = contact;
    let httpClient = this.httpClient,
      verifyUrl = this.verifyUrl,
      contactUrl = this.contactUrl,
      subSuccess = this.subSuccess;

    grecaptcha.ready(function() {
      grecaptcha.execute('6LfL78EZAAAAAPB70A8L4fbpDGmjoaickJY4MRYQ', {action: 'submit'}).then(function(token) {
        httpClient.post<RecaptchaResponse>(verifyUrl, `{"token":"${token}"}`).subscribe(data => {
          if (data?.success && data?.score > 0.5) {
            httpClient.post<boolean>(contactUrl, JSON.stringify(contact)).subscribe(data => {
              console.log('success', data);
              subSuccess.next(data);
            });
          } else {
            subSuccess.next(false);
          }
        });
      });
    });
  }

  sendWholesaleEmail (contactWholesale: ContactWholesale) {
    this.contactWholesale = contactWholesale;
    let httpClient = this.httpClient,
      verifyUrl = this.verifyUrl,
      contactWholesaleUrl = this.contactWholesaleUrl,
      subSuccess = this.subSuccess;

      grecaptcha.ready(function() {
        grecaptcha.execute('6LfL78EZAAAAAPB70A8L4fbpDGmjoaickJY4MRYQ', {action: 'submit'}).then(function(token) {
          httpClient.post<RecaptchaResponse>(verifyUrl, `{"token":"${token}"}`).subscribe(data => {
            if (data?.success && data?.score > 0.5) {
              httpClient.post<boolean>(contactWholesaleUrl, JSON.stringify(contactWholesale)).subscribe(data => {
                console.log('success', data);
                subSuccess.next(data);
              });
            } else {
              subSuccess.next(false);
            }
          });
        });
      });
  }
}
