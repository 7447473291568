import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HomeComponent } from './pages/home/home.component'
import { SettingsComponent } from './pages/settings/settings.component'
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { AlertsComponent } from './partials/alerts/alerts.component'
import { DefaultDialogComponent } from './partials/alerts/default-dialog/default-dialog.component'
import { FooterComponent } from './partials/footer/footer.component'
import { SettingsService } from './services/settings.service'
import { StorageService } from './services/storage.service'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule } from '@angular/forms'
import { AboutComponent } from './pages/about/about.component';
import { NavbarComponent } from './partials/navbar/navbar.component';
import { ShopComponent } from './pages/shop/shop.component'
import { ShopService } from './services/shop.service'
import { HttpClientModule } from '@angular/common/http';
import { CartComponent } from './partials/cart/cart.component';
import { CartSheetComponent } from './partials/cart-sheet/cart-sheet.component';
import { LearnComponent } from './pages/learn/learn.component';
import { ContactComponent } from './partials/contact/contact.component';
import { SubscribeComponent } from './partials/subscribe/subscribe.component';
import { TestComponent } from './pages/test/test.component'
import { MatDialogModule } from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { MatChipsModule } from '@angular/material/chips'
import { MatInputModule } from '@angular/material/input'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatCardModule } from '@angular/material/card'
import { MatListModule } from '@angular/material/list'
import { MatBottomSheetModule } from '@angular/material/bottom-sheet'
import { ContactService } from './services/contact.service';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { ContactWholesaleComponent } from './partials/contact-wholesale/contact-wholesale.component'

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SettingsComponent,
    NotFoundComponent,
    AlertsComponent,
    DefaultDialogComponent,
    FooterComponent,
    AboutComponent,
    NavbarComponent,
    ShopComponent,
    CartComponent,
    CartSheetComponent,
    LearnComponent,
    ContactComponent,
    SubscribeComponent,
    TestComponent,
    ContactUsComponent,
    ContactWholesaleComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MatDialogModule,
    MatTooltipModule,
    MatButtonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatListModule,
    MatBottomSheetModule,
    HttpClientModule
  ],
  providers: [
    SettingsService,
    StorageService,
    ShopService,
    ContactService
  ],
  entryComponents: [
    DefaultDialogComponent,
    CartSheetComponent
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
