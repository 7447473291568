import { Component, OnInit } from '@angular/core';
import { ContactWholesale } from 'src/app/objects/contact-wholesale';
import { ContactService } from 'src/app/services/contact.service';

@Component({
  selector: 'app-contact-wholesale',
  templateUrl: './contact-wholesale.component.html',
  styleUrls: ['./contact-wholesale.component.scss']
})
export class ContactWholesaleComponent implements OnInit {
  contactWholesale: ContactWholesale = new ContactWholesale('');
  success: boolean = false;
  sent: boolean = false;

  constructor(
    private contactService: ContactService
  ) { }

  ngOnInit(): void {
    this.contactService.obsSuccess.subscribe(success => {
      this.success = success;
    });
  }

  send () {
    this.sent = true;
    this.contactService.sendWholesaleEmail(this.contactWholesale);
  }
}
