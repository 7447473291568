<div class="container">
    <div class="page">
        <h2>Read the brochure:</h2>
        <div class="section" style="margin-bottom: 50px;">
            <img src="https://static-ux.s3.us-east-2.amazonaws.com/recapture-organics/ui/brochure1.png" alt="Our mission: healing the world with cannabinoids in an organic, healthy way." />
            <img src="https://static-ux.s3.us-east-2.amazonaws.com/recapture-organics/ui/brochure2.png" alt="We are on Facebook, Instagram, and LinkedIn" />
            <img src="https://static-ux.s3.us-east-2.amazonaws.com/recapture-organics/ui/brochure3.png" alt="Recapture Organics - Recapture your life through organic cannabinoids." />
            <img src="https://static-ux.s3.us-east-2.amazonaws.com/recapture-organics/ui/brochure4.png" alt="Recapture Organics has something for everyone! We use only USDA-certified organic hemp-derived CBD and have options in full- and broad-spectrum." />
            <img src="https://static-ux.s3.us-east-2.amazonaws.com/recapture-organics/ui/brochure5.png" alt="The endocannabinoid system is the largest neurotransmitter system in the human body and is imperative in maintaining health." />
            <img src="https://static-ux.s3.us-east-2.amazonaws.com/recapture-organics/ui/brochure6.png" alt="Cannabis oil in its purest form is called full-spectrum because it contains the full range of cannabinoids and terpenes from the plant. Broad-spectrum oil is similar, but is THC-free." />
        </div>
    </div>
</div>
