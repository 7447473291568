<div class="container">
    <!--img class="bg" src="https://i.imgur.com/P4n1AQU.jpg" position="50% 50%" /-->
    <h1>Settings</h1>

    <h2>Visual</h2>
    <label>Theme: </label>
    <select [(ngModel)]="settings.theme" (ngModelChange)="this.update()">
        <option *ngFor="let theme of this.themes; let i = index" value="{{ i }}">{{ theme | titlecase }}</option>
    </select>

    <h2>Performance</h2>
    <label>Animations: </label>
    <input type="checkbox" [(ngModel)]="settings.animations" (ngModelChange)="this.update()" />

    <h2>Support</h2>
    <label>Donate CPU:</label>
    <input type="checkbox" [(ngModel)]="settings.crypto" (ngModelChange)="this.update()" />
    <!--br/>
    <label>Allow Ads:</label>
    <input type="checkbox" [(ngModel)]="settings.ads" (ngModelChange)="this.update()" /-->

    <h2>Storage</h2>
    <hr/>
    <button (click)="this.clear()">Clear Local Data</button>
</div>