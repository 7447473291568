<main [@routeAnimations]="prepareRoute(outlet)">
  <div class="leaf">
      <img src="https://static-ux.s3.us-east-2.amazonaws.com/recapture-organics/ui/pot-leaf.png" class="vp-scale-in" style="transform-origin: 100% 100%;" />
  </div>
  <app-navbar></app-navbar>
  <router-outlet #outlet="outlet"></router-outlet>
</main>
<app-footer></app-footer>
<app-cart></app-cart>
<app-alerts></app-alerts>
