<div class="container">
    <div class="intro section">
        <h2>Shop</h2>
        <h3>Know the difference:<br><span>Full-Spectrum</span><br/>Broad-Spectrum</h3>
        <button mat-raised-button color="primary" routerLink="/learn">LEARN MORE</button>
    </div>
    <div class="mission section">
        <img src="https://static-ux.s3.us-east-2.amazonaws.com/recapture-organics/ui/banner.png" />
        <h5>OUR PRODUCTS</h5>
        <h3>made from the finest 100% USDA organic, non-gmo hemp produced in the USA.</h3>
    </div>
    <div class="catalog" *ngIf="this.catalog; else loading">
        <div class="wrapper" *ngFor="let item of this.catalog">
            <div class="item" *ngIf="!item.is_deleted">
                <div class="section " *ngFor="let variation of item.item_data.variations">
                    <div [id]="variation.id" class="text">
                        <h5>{{ item.item_data.name }}</h5>
                        <h4>{{ variation.item_variation_data.name }}</h4>
                        <p>{{ item.item_data.description }}</p>
                        <p class="price">{{ variation.item_variation_data.price_money.amount / 100 | currency }} <sub>+ tax</sub></p>
                        <button mat-raised-button color="accent" *ngIf="!variation.quantity; else quantityInput" (click)="this.addToCart(item.id, variation.id)">ADD TO CART</button>
                        <ng-template #quantityInput>
                            <mat-form-field>
                                <mat-label>Quantity:</mat-label>
                                <input matInput type="number" min="0" max="100" [(ngModel)]="variation.quantity" (input)="this.updateCart(item.id, variation.id, $event)" />
                            </mat-form-field>
                        </ng-template>
                    </div>
                    <div class="zoom-hover img">
                        <div style="background-image: url(https://static-ux.s4.us-east-2.amazonaws.com/recapture-organics/store/{{ variation.id }}.png); background-position: 100% 50%;"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #loading>
        <div id="spinner">
            <mat-spinner></mat-spinner>
        </div>
    </ng-template>
</div>
