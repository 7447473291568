<!--img class="bg" src="https://i.imgur.com/67pqdgI.jpg" /-->
<div class="container">
    <div class="intro section">
        <h3><span>Recapture your life</span><br/>through organic<br>cannabinoids.</h3>
    </div>
    <div class="mission section">
        <img src="https://static-ux.s3.us-east-2.amazonaws.com/recapture-organics/ui/banner.png" />
        <h5>OUR MISSION</h5>
        <h3>Healing the world with cannabinoids in an organic, healthy way.</h3>
    </div>
    <div class="products section ">
        <div class="text">
            <h5>OUR PRODUCTS</h5>
            <h4>Recapture Organics has something for everyone.</h4>
            <p>While using only USDA-Certified Organic hemp-derived CBD, we provide both Full Spectrum products for those seeking maximum effectiveness along with Broad Spectrum/THC-Free products for those that desire the benefits of the Entourage Effect but with 0% THC and 0% worries.</p>
        </div>
        <div class="zoom-hover img">
            <div style="background-image: url(https://static-ux.s3.us-east-2.amazonaws.com/recapture-organics/products/products-1.jpg); background-position: 50% 50%;"></div>
        </div>
    </div>
    <div class="resources section ">
        <div class="text">
            <h5>OUR RESOURCES</h5>
            <h4>What is the Entourage Effect?</h4>
            <p>The Entourage Effect is the multitude of added benefits one gets from utilizing a wide spectrum of cannabis plant components in unison, in comparison to the minimal benefits one would feel whilst taking advantage of only one component at a time &mdash; for example, a pure CBD Isolate product.</p>
            <button mat-raised-button color="accent" routerLink="/learn">LEARN MORE</button>
        </div>
        <div class="zoom-hover img">
            <div style="background-image: url(https://static-ux.s3.us-east-2.amazonaws.com/recapture-organics/ui/brochure6.png); background-position: 50% 50%;"></div>
        </div>
    </div>
    <app-contact></app-contact>
</div>
