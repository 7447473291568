<div class="container">
    <div class="header section zoom-hover">
        <div style="background-image: url(https://static-ux.s3.us-east-2.amazonaws.com/recapture-organics/ui/sunset-plants.jpg);background-position: 50% 73%"></div>
    </div>
    <div class="about section ">
        <div class="text">
            <h5>ABOUT US</h5>
            <h4>Welcome, friends.<br>We are Recapture Organics.</h4>
            <p>We believe in providing only the best quality hemp products. Our USDA-certified organic, non-GMO products come from plants grown by a family of agricultural pioneers in Oregon.</p>
            <p>For now, you can enjoy our high-quality CBD with tinctures and a topical rub, with more options coming in the future! All products are vegan-friendly.</p>
            <p>We also have full-spectrum and broad-spectrum variations of all of our products. Broad-spectrum products <b>do not</b> contain THC. Full-spectrum products contain blow the US legal limit of 0.3%</p>
            <button mat-raised-button color="accent" routerLink="/learn">LEARN MORE</button>
        </div>
        <div class="zoom-hover img">
            <div style="background-image: url(https://static-ux.s3.us-east-2.amazonaws.com/recapture-organics/products/products-2.jpg); background-position: 50% 50%;"></div>
        </div>
    </div>
    <app-contact></app-contact>
</div>
