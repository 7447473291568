<div class="subscribe section ">
    <h4>Sign up for our newsletter</h4>
    <p>Subscribe for discounts, CBD insights, and much more!</p>
    <form>
        <mat-form-field appearance="standard">
            <i class="far fa-envelope" matPrefix></i>
            <mat-label>Email:</mat-label>
            <input matInput />
            <i class="fas fa-angle-right" matSuffix></i>
        </mat-form-field>
    </form>
</div>