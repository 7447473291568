<div class="header">
    <h2>Cart</h2>
    <i class="fas fa-times" (click)="this.dismiss()"></i>
</div>
<mat-list *ngFor="let item of this.data">
    <div *ngFor="let variation of item.item_data.variations">
        <mat-list-item *ngIf="!item.is_deleted && variation.quantity > 0">
            <span mat-line>
                <p>{{ variation.item_variation_data.name }}</p>
                <mat-form-field>
                    <mat-label>Quantity:</mat-label>
                    <input matInput type="number" min="0" max="100" [(ngModel)]="variation.quantity" (input)="this.updateCart(item.id, variation.id, $event)" />
                </mat-form-field>
                <i class="fas fa-minus" (click)="this.removeItem(item.id, variation.id)"></i>
                <i class="fas fa-plus" (click)="this.addItem(item.id, variation.id)"></i>
                <i class="fas fa-times" (click)="this.clearItem(item.id, variation.id)"></i>
            </span>
        </mat-list-item>
    </div>
</mat-list>