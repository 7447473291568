<div id="contactUsWholesale" class="contact section vp-fade-in">
  <h4>Contact Us</h4>
  <p>Contact us for special discounts on wholesale orders.</p>
  <form>
      <mat-form-field appearance="standard">
          <i class="fas fa-user" matPrefix></i>
          <mat-label>Name:</mat-label>
          <input name="name" matInput [(ngModel)]="this.contactWholesale.name" />
      </mat-form-field>
      <mat-form-field appearance="standard">
          <i class="far fa-envelope" matPrefix></i>
          <mat-label>Email:</mat-label>
          <input name="email" matInput [(ngModel)]="this.contactWholesale.email" />
      </mat-form-field>
      <mat-form-field appearance="standard">
          <i class="far fa-sticky-note" matPrefix></i>
          <mat-label>Message:</mat-label>
          <textarea name="message" matInput [(ngModel)]="this.contactWholesale.message"></textarea>
      </mat-form-field>
      <button mat-raised-button color="accent" (click)="this.send()" *ngIf="!this.sent; else loading">SEND</button>
  </form>
  <ng-template #loading>
    <div id="spinner" *ngIf="!this.success; else successful">
        <mat-spinner></mat-spinner>
    </div>
  </ng-template>
  <ng-template #successful>
    <h5>Success!</h5>
  </ng-template>
</div>
