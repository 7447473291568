<div id="navbar" class="navbar vp-fade-in">
    <nav>
        <div class="logo" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <img src="https://static-ux.s3.us-east-2.amazonaws.com/recapture-organics/ui/recapture-organics-logo.png" />
            <h1>Recapture<br/><span>Organics</span></h1>
        </div>
        <ul>
            <li><a routerLink="/learn/" routerLinkActive="active">Learn</a></li>
            <li><a routerLink="/about/" routerLinkActive="active">About</a></li>
            <li><a routerLink="/contact/" routerLinkActive="active">Contact</a></li>
            <li>
                <div>
                    <a href="https://www.instagram.com/recaptureorganics/" target="_blank"><i class="fab fa-instagram"></i></a>
                    <a href="https://www.facebook.com/recapture.organics" target="_blank"><i class="fab fa-facebook"></i></a>
                    <a href="https://www.linkedin.com/in/recapture-organics-476753196/" target="_blank"><i class="fab fa-linkedin"></i></a>
                </div>
            </li>
        </ul>
    </nav>
</div>
