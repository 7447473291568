import { Component, OnInit } from '@angular/core';
import { ContactService } from 'src/app/services/contact.service';
import { Contact } from 'src/app/objects/contact';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contact: Contact = new Contact('');
  success: boolean = false;
  sent: boolean = false;

  constructor(
    private contactService: ContactService
  ) { }

  ngOnInit() {
    this.contactService.obsSuccess.subscribe(success => {
      this.success = success;
    });
  }

  send () {
    this.sent = true;
    this.contactService.sendEmail(this.contact);
  }
}
